<template>
  <div>
    <el-form ref="dataForm" label-width="80px" :rules="rules" :model="temp">
      <el-form-item label="标题" prop="cTitle">
        <el-input v-model="temp.cTitle"></el-input>
      </el-form-item>
      <el-form-item label="链接地址" prop="cUrl">
        <el-input v-model="temp.cUrl"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      dialogVisible: false,
      temp: {
        cTitle: "",
        cUrl: "",
      },
      rules: {
        cTitle: [
          {
            required: true,
            message: "标题必填",
            trigger: "blur",
          },
        ],
        cUrl: [
          {
            required: true,
            message: "链接地址必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$api.config_row(this.temp).then((res) => {
        this.temp = res.data.row;
      });
    },
    save() {
      // console.log(this.temp);
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // this.temp.id = 0; // mock a id
          this.$api.config_save(this.temp).then((res) => {
            this.getList();

            this.$notify({
              title: "温馨提示",
              message: res.data.msg,
              type: "success",
              duration: 2000,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
